import React, { useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { mapValues } from "lodash-es";
import { ACTIVE_TABLE_FILE_TYPE_MAP } from "../../../utils/activeTable";
import styled from "@emotion/styled";
import useActiveTableDropdown from "./useActiveTableDropdown";
import { amendColumnForActiveGrid } from "../useActiveTableSettings";

const Container = styled.div`
  /*noinspection CssUnusedSymbol*/
  .ag-cell-value.no-overflow-hidden {
    overflow: visible;
  }
`;

export default function ActiveGrid(props) {
  const {
    rowData,
    columns,
    handleChange,
    height = 500,
    space,
    activeTableDataLoading,
  } = props;
  const gridRef = useRef(null);
  const includePagination = rowData?.length > 20;
  const { loadValues, loadValuesSearch } = useActiveTableDropdown();

  // override no rows message
  const noRowsOverlayComponentParams = useMemo(() => {
    const message = columns.length
      ? "No rows to show"
      : "No visible columns to show";

    return {
      noRowsMessageFunc: () => message,
    };
  }, [columns.length]);

  function getRowNodeId(params) {
    return params.data.uniqueRowUuid;
  }

  const paginationProps = {
    ...(includePagination && {
      pagination: true,
      paginationPageSizeSelector: [50, 100, 200, 300, 500],
      paginationPageSize: 100,
      paginationAutoPageSize: false,
    }),
  };

  const columnDefs = useMemo(() => {
    return columns.map((col) =>
      amendColumnForActiveGrid(col, loadValues, loadValuesSearch)
    );
  }, [columns, loadValues, loadValuesSearch]);

  return (
    <Container
      className="ag-theme-quartz"
      style={{ height, marginBottom: space ? 24 : 0 }}
      data-cy="active-grid-block"
    >
      {/* The AG Grid component */}
      <AgGridReact
        ref={gridRef}
        rowData={columns.length ? rowData : activeTableDataLoading ? null : []}
        columnDefs={columnDefs}
        onCellValueChanged={handleChange}
        getRowId={getRowNodeId}
        noRowsOverlayComponent={CustomNoRowsOverlay}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        loading={activeTableDataLoading}
        columnTypes={{
          date: {},
          datetime: {},
          select: {},
          text: {},
          string: {},
          currency: {},
          boolean: {},
          decimal: {},
          bigint: {},
          integer: {},
          float: {},
          number: {},
          ...mapValues(ACTIVE_TABLE_FILE_TYPE_MAP, () => ({})),
        }}
        {...paginationProps}
      />
    </Container>
  );
}

// showing custom message if there is no any visible column
function CustomNoRowsOverlay(props) {
  return <div>{props.noRowsMessageFunc()}</div>;
}
