import { chartTypeKeyMap } from "../../utils/constants/chartConstants";

export default function (
  groupBy,
  valueKeys,
  yLabelOverride,
  activeValueKey,
  meta
) {
  const yLabelText =
    yLabelOverride ??
    (groupBy
      ? (meta?.fields ?? []).find((f) => f.alias === valueKeys?.[0].alias)
          ?.label
      : activeValueKey?.displayName);
  return { yLabelText };
}

export function mapYAxisLabel(
  chartType,
  valueKeys,
  meta,
  rightYAxisLabelOverride,
  isLine
) {
  const validForRightAxis =
    chartType === chartTypeKeyMap.StackedBars ||
    chartType === chartTypeKeyMap.GroupedBar;
  const lineKey = valueKeys.find((k) => isLine || k.extras?.shape === "line");
  if (!validForRightAxis || !lineKey) {
    return { availableRightAxis: false };
  }
  const metaMatch = meta?.fields?.find((f) => f.alias === lineKey.alias);

  return {
    availableRightAxis: true,
    rightLabel: rightYAxisLabelOverride || metaMatch.label,
  };
}
