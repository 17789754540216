import React, { useState } from "react";
import InputSet from "../../../../UI/Form/InputSet/InputSet";
import styled from "@emotion/styled";
import { cloneDeep } from "lodash-es";
import Flex from "../../../../UI/Flex/Flex";
import Checkbox from "../../../../UI/Form/Checkbox/Checkbox";
import ActiveTableOptionsReorder from "./ActiveTableOptionsReorder";
import { scrollbarDe } from "../../../../styles/styledSnippets";

const Container = styled.div`
  color: ${(props) => props.theme.text.primary};
  font-size: 14px;
  margin: 12px 0 18px 0;
`;

const OptionsContainer = styled.div(
  ({ theme }) => `
  max-height: 300px;
  ${scrollbarDe(theme)};
  overFlow-y: auto;
`
);

export default function ActiveTableOptionsSettings(props) {
  const { setOptions, colDef } = props;
  const clonedColDef = cloneDeep(colDef);
  const [reorderMode, setReorderMode] = useState(false);

  const options = (clonedColDef.cellEditorParams.values ?? ["None..."]).map(
    (v) => ({
      value: v,
    })
  );

  return (
    <div>
      <Flex justifyContent="space-between">
        <Container>Set options to be selected</Container>
        <Checkbox
          label="Reorder"
          onChange={() => setReorderMode(!reorderMode)}
        />
      </Flex>

      <OptionsContainer>
        {reorderMode ? (
          <ActiveTableOptionsReorder
            options={options}
            setOptions={setOptions}
            uuid={colDef.colId}
          />
        ) : (
          <InputSet
            onChange={(nextOptions) => setOptions(colDef.colId, nextOptions)}
            values={options}
          />
        )}
      </OptionsContainer>
    </div>
  );
}
