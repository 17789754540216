import React, { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { createVisualization } from "../../../store/actions";
import IOButton from "../../../UI/Form/Button/IOButton";
import DisplayName from "./EditableComponents/DisplayName";
import SourceField from "./EditableComponents/SourceField";
import Type from "./EditableComponents/Type";
import ColumnWidth from "./EditableComponents/ColumnWidth";
import { aggregations } from "../../../utils/constants/constants";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash-es";

const Container = styled.div(
  ({ theme }) => `
  width: calc(100vw - 360px);
  height: 90px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  & > span {
    opacity: 0.5;
    text-align: left;
    font-size: 14px;
    svg {
      margin-right: 10px;
    }
  }
`
);

const Components = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  & > div {
    width: 20%;
    text-align: left;
    margin-right: 15px;
    label {
      color: black;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 15px;
`;

const EditableMenu = React.forwardRef((props, ref) => {
  const activeTab = useSelector((state) => state.layout.activeTab);
  const history = useHistory();
  const { pages, loading } = useSelector((state) => state.pageManagement);
  const dispatch = useDispatch();

  const activePage = useMemo(
    () => pages.find((page) => page.uuid === activeTab.uuid),
    [activeTab.uuid, pages]
  );

  const block = useMemo(
    () =>
      activePage.blocks.find((block) =>
        block.visualizations.find((vis) => vis.uuid === props.visualizationUuid)
      ),
    [activePage.blocks, props.visualizationUuid]
  );

  const chart = useMemo(
    () =>
      block?.visualizations.find((vis) => vis.uuid === props.visualizationUuid),
    [block?.visualizations, props.visualizationUuid]
  );

  const [visualization, setVisualization] = useState(chart);

  const innerProps = {
    ...props,
    visualization,
    setVisualization,
  };

  function onVisualizationSave() {
    dispatch(
      createVisualization(
        activeTab.uuid,
        block.uuid,
        visualization.query.uuid,
        visualization.overrides,
        visualization.filters,
        visualization.orders,
        { ...visualization.settings, overrides: visualization.overrides },
        visualization.uuid,
        visualization.sortOrder,
        true
      )
    );
  }

  return (
    <Container ref={ref}>
      <span>
        <FontAwesomeIcon icon={["fas", "bolt"]} />
        Quick Edit
      </span>

      <div style={{ display: "flex" }}>
        <Components>
          <DisplayName {...innerProps} />
          <Type {...innerProps} />
          <SourceField {...innerProps} />
          <ColumnWidth {...innerProps} />
        </Components>
        <Actions>
          <span>Need more ?</span>
          <IOButton
            onClick={() =>
              history.push(`/table-editor/${props.visualizationUuid}`)
            }
            smallPadding
            style={{ fontSize: 12 }}
            outlinePrimary
          >
            Open Power Editor
          </IOButton>
          <br />
          <IOButton
            smallPadding
            onClick={onVisualizationSave}
            processing={loading}
            style={{ fontSize: 12 }}
            disabled={isEqual(chart, visualization)}
            whiteBg
          >
            Save
          </IOButton>
        </Actions>
      </div>
    </Container>
  );
});

// when you need to update the format or change source column name
// you need first remove aggregation string (SumFieldName)
export function getRidOfAggregation(
  aggregated = "",
  overrides = [],
  isParameterized
) {
  if (typeof aggregated !== "string" || isParameterized) {
    return aggregated;
  }

  // we can have fields which names can start with aggregation prefix but they did not aggregated
  // so we should not remove this prefix
  const condition = overrides.some((override) => override.name === aggregated);

  const prefixes = aggregations.map((aggregation) => aggregation.prefix);
  const prefix = prefixes.find((p) => (aggregated ?? "").startsWith(p)) ?? "";
  return condition ? aggregated : (aggregated ?? "").replace(prefix, "");
}

export default EditableMenu;
