import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

/**
 * Encapsulates drag-and-drop behavior for its children.
 */
export default function DraggableContainer({
  items,
  onDragStart,
  onDragEnd,
  renderItem,
}) {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable
                key={item.id || `${item.value}-${index}`}
                draggableId={item.id || `${item.value}-${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      marginBottom: 8,
                      ...provided.draggableProps.style, // Keeps React DnD style
                    }}
                  >
                    {renderItem(
                      item,
                      index,
                      provided.dragHandleProps,
                      snapshot
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

DraggableContainer.propTypes = {
  items: PropTypes.array.isRequired, // Array of items to render
  onDragEnd: PropTypes.func.isRequired, // Function triggered on reordering
  renderItem: PropTypes.func.isRequired, // Render function for each item
};
