import React, { useEffect, useRef, useMemo } from "react";
import Input from "../../../UI/Form/Input/Input";
import ActiveTableDisplayFormat from "./ActiveTableDisplayFormat";
import styled from "@emotion/styled";
import ActiveTableColumnSettingsPanel from "./ActiveTableColumnSettingsPanel";
import { editOptions, getTypeAndDisplayOptions, typeOptions } from "./helper";
import ActiveTableOptions from "./ActiveTableOptions/ActiveTableOptions";
import Flex from "../../../UI/Flex/Flex";
import { getActiveTableTypeByQueryField } from "../activeTableHelpers";

const inputWidthPresets = [40, 60, 80, 100, 200, 300, 400, 500, 700, 1000];
const inputWidthOptions = [
  { value: null, label: "Default" },
  ...inputWidthPresets.map((width) => ({
    value: width,
    label: `${width} pixels`,
  })),
];

const Container = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const OuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

const Instructions = styled.div`
  color: ${(props) => props.theme.text.primary};
`;

export default function ActiveTableSettingsRow(props) {
  const {
    colDef,
    index,
    updateColumn,
    updateColumnConfig,
    updateTypeConfig,
    setOptions,
    produceColumn,
    accessGroups,
    setAccessGroups,
    validateFieldName,
    setActiveColumn,
    activeTableUpdateLoading,
    setEditing,
    setDisplayFormatOverride,
    columnErrorsObject,
    setColumnError,
    queryFields,
  } = props;

  const fieldNameValidation = columnErrorsObject?.name?.join("\n");
  const fieldDisplayNameValidation =
    columnErrorsObject?.["displaySettings.displayName"]?.join("\n");

  const fieldNameRef = useRef(null);

  useEffect(() => {
    if (fieldNameRef.current && colDef?.colId) {
      fieldNameRef.current.focus();
    }
  }, [colDef?.colId]);

  useEffect(() => {
    if (colDef) {
      validateFieldName(colDef);
    }
  }, [colDef, validateFieldName]);

  const availableTypeOptions = useMemo(() => {
    return typeOptions.filter((v) => !v.deprecated || v.value === colDef?.type);
  }, [colDef?.type]);

  if (!colDef) {
    return (
      <Instructions data-cy="settings-message">Select a column</Instructions>
    );
  }

  function handleChangeField(e) {
    updateColumn(index, { ...colDef, field: e.target.value.trimLeft() });
  }

  function handleEditStyleChange(e) {
    const editors = {
      text: "agTextCellEditor",
      select: "agSelectCellEditor",
    };

    updateColumn(index, {
      ...colDef,
      type: e.value,
      displaySettings: { ...(colDef.displaySettings ?? {}), editMode: e.value },
      cellEditor: editors[e.value],
    });
  }

  function handleFormatChange(option) {
    setDisplayFormatOverride(colDef.colId, option.value);
  }

  function handleLocked(e) {
    if (colDef.queryOnly) {
      const nextColDef = {
        ...colDef,
        editable: !e.target.checked,
        colId: "temp",
      };
      if (!nextColDef.type) {
        nextColDef.type = getActiveTableTypeByQueryField(
          queryFields,
          colDef.field
        );
      }
      updateColumn(index, nextColDef);
      setActiveColumn("temp");
      updateColumnConfig("temp", colDef.field, index, nextColDef);
    } else {
      updateColumn(index, { ...colDef, editable: !e.target.checked });
    }
  }

  function handleChangeType(e) {
    // Update in useActiveTableSettings hook
    const nextColumnSetting = {
      ...colDef,
      type: e.value,
      // reset format override
      cellEditorParams: {
        ...(colDef.cellEditorParams ?? {}),
        displayFromatOverride: null,
      },
      // reset format override
      displaySettings: {
        ...(colDef.displaySettings ?? {}),
        displayFromatOverride: null,
      },
    };

    updateTypeConfig(
      colDef?.colId,
      nextColumnSetting,
      index,
      nextColumnSetting
    );
  }

  function handleFieldNameChange() {
    setEditing(false);
    if (!fieldNameValidation) {
      updateColumnConfig(colDef?.colId, colDef?.field, index, colDef);
    }
  }

  const lockedField = !colDef.displaySettings?.activeOnly;

  const { type, displayOptions, displayOptionType } =
    getTypeAndDisplayOptions(colDef);

  const stylesForNotForQueryOnly = colDef.queryOnly ? { opacity: 0.4 } : {};

  return (
    <div>
      <OuterContainer>
        <Container>
          <Flex style={{ marginRight: 8 }} gap="0.5rem">
            <Input
              value={colDef?.field}
              label="Field name"
              style={{ ...stylesForNotForQueryOnly, padding: 8, marginTop: 1 }}
              onChange={handleChangeField}
              cy="field-name"
              onBlur={handleFieldNameChange}
              errorMessage={fieldNameValidation}
              invalid={!!fieldNameValidation}
              disabled={lockedField}
              onFocus={() => setEditing(true)}
              ref={fieldNameRef}
            />
            <Input
              value={colDef.cellEditorParams.displayName ?? ""}
              label="Field display name"
              placeholder={colDef.headerName}
              style={{ padding: 8, marginTop: 1 }}
              onChange={(e) =>
                produceColumn((column) => {
                  const value = e.target.value;
                  if (value) {
                    column.cellEditorParams.displayName = value;
                  } else {
                    delete column.cellEditorParams.displayName;
                  }
                }, e)
              }
              cy="field-display-name"
              errorMessage={fieldDisplayNameValidation}
              invalid={!!fieldDisplayNameValidation}
            />
          </Flex>
          <ActiveTableDisplayFormat
            updateColumn={handleChangeType}
            style={stylesForNotForQueryOnly}
            options={availableTypeOptions}
            cy="select-field-type"
            value={type}
            label="Field type"
            isDisabled={
              !!fieldNameValidation ||
              colDef.queryOnly ||
              activeTableUpdateLoading
            }
          />

          <ActiveTableDisplayFormat
            updateColumn={handleFormatChange}
            options={displayOptions}
            cy="display-style"
            value={displayOptionType}
            label="Display Style"
            isDisabled={!!fieldNameValidation || activeTableUpdateLoading}
            width={270}
          />

          {colDef?.editable &&
          ["string", "select"].find((t) => t === colDef?.type) ? (
            <ActiveTableDisplayFormat
              updateColumn={handleEditStyleChange}
              style={stylesForNotForQueryOnly}
              options={editOptions}
              cy="select-edit-style"
              value={colDef.type === "select" ? "select" : "text"}
              label="Edit style"
              isDisabled={!!fieldNameValidation}
            />
          ) : null}
          <ActiveTableDisplayFormat
            updateColumn={(option) =>
              produceColumn((column) => {
                const value = option.value;
                if (value) {
                  column.width = value;
                } else {
                  delete column.width;
                }
              })
            }
            addMissingOptionOnTheFly
            options={inputWidthOptions}
            value={colDef.width ?? null}
            label="Column width"
          />
        </Container>
        <ActiveTableColumnSettingsPanel
          handleLocked={handleLocked}
          locked={!colDef?.editable}
          colDef={colDef}
          accessGroups={accessGroups}
          setAccessGroups={setAccessGroups}
          activeTableUpdateLoading={activeTableUpdateLoading}
        />
      </OuterContainer>
      {colDef?.type === "select" ? (
        <ActiveTableOptions
          colDef={colDef}
          setOptions={setOptions}
          produceColumn={produceColumn}
          columnErrorsObject={columnErrorsObject}
          setColumnError={setColumnError}
        />
      ) : null}
    </div>
  );
}
