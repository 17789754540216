import React, { useEffect, useState, useCallback } from "react";
import styled from "@emotion/styled";
import ActiveTableSettingsRow from "./ActiveTableSettingsRow";
import usePrevious from "../../../utils/usePrevious";
import ActionSettingsButtons from "./ActionSettingsButtons";
import ConfirmDeleteActiveSettings from "./ConfirmDeleteActiveSettings";
import { themeGrayIf } from "../../../styles/styledSnippets";
import ActiveTableColumnSelector from "../ColumnSettings/ActiveTableColumnSelector";

const Container = styled.div`
  color: ${(props) => props.theme.gray.gray900};
  background: ${(props) => props.theme.background.secondary};
  padding: 14px;
  margin-bottom: 12px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid ${themeGrayIf(800, 300)};
  min-height: 184px;
`;

export default function ActiveTableSettings(props) {
  const {
    dataSourceForPermissionsSet,
    columns,
    setColumns,
    updateColumn,
    updateSettings,
    updateColumnConfig,
    performDeleteColumn,
    addColumn,
    setOptions,
    produceColumnByUuid,
    accessGroups,
    setAccessGroups,
    isDirty,
    setAllLocked,
    data,
    validateFieldName,
    isInvalid,
    activeTableUpdateLoading,
    setDisplayFormatOverride,
    columnErrorsObject,
    setColumnError,
    queryFields,
  } = props;
  const [activeColumn, setActiveColumn] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [editing, setEditing] = useState(false);

  const prevColumns = usePrevious(columns);

  // This is needed to keep active column open when name changed or new column;
  useEffect(() => {
    if (columns && prevColumns) {
      const hasPrevTemp = prevColumns.find((col) => col.colId === "temp");
      const hasCurrentTemp = columns.find((col) => col.colId === "temp");
      convertTempToActualColId(hasPrevTemp, hasCurrentTemp);
    }
    function convertTempToActualColId(hasPrevTemp, hasCurrentTemp) {
      if (hasPrevTemp && !hasCurrentTemp) {
        const newColumn = columns.find(
          (col) => col.colId !== "temp" && col.field === hasPrevTemp.field
        );
        if (newColumn) {
          setActiveColumn(newColumn.colId);
        }
      }
    }
  }, [columns, prevColumns]);

  function handleSetActive(uuid) {
    setActiveColumn(activeColumn === uuid ? null : uuid);
  }

  // Fired on name or name creation only
  function handleUpdateColumnConfig(
    colId,
    nextValue,
    index,
    nextColumnSetting
  ) {
    updateColumnConfig(colId, nextValue, "name", index, nextColumnSetting);
  }

  function handleUpdateTypeConfig(colId, nextValue, index, nextColumnSetting) {
    updateColumnConfig(colId, nextValue, "type", index, nextColumnSetting);
  }

  function handleAddColumn() {
    addColumn();
    setActiveColumn("temp");
  }

  function active(col) {
    return col.colId === activeColumn;
  }

  const hasTempField = columns.find((col) => col.colId === "temp");

  function handleSetDeleteColumn() {
    const fieldName = columns.find((col) => col.colId === activeColumn).field;
    const hasDataInField = data.find((row) => row[fieldName]);
    if (hasDataInField) {
      setDeleting(true);
    } else {
      deleteActiveColumn();
    }
  }

  function confirmDelete() {
    setDeleting(false);
    deleteActiveColumn();
  }

  function deleteActiveColumn() {
    performDeleteColumn(activeColumn);
    setActiveColumn(null);
  }

  const produceColumn = useCallback(
    (callback) => produceColumnByUuid(activeColumn, callback),
    [activeColumn, produceColumnByUuid]
  );

  if (deleting)
    return (
      <Container>
        <ConfirmDeleteActiveSettings
          cancelDeleting={() => setDeleting(false)}
          confirmDelete={confirmDelete}
          column={columns.find((col) => col.colId === activeColumn)}
          activeColumn={activeColumn}
        />
      </Container>
    );

  return (
    <Container>
      <div
        style={{
          marginTop: -2,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            fontSize: 10,
            color: "gray",
            marginRight: 4,
            display: "inline-block",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setSettingsMode(false);
            setColumns((prev) => prev.filter((col) => col.colId !== "temp"));
          }}
        >
          close settings
        </div>
      </div>
      <ActiveTableColumnSelector
        handleSetActive={handleSetActive}
        hasTempField={hasTempField}
        columns={columns}
        active={active}
        handleAddColumn={handleAddColumn}
        hideVisible
        hideChecklist
        setNextColumns={setColumns}
      />
      <ActiveTableSettingsRow
        colDef={columns.find((c) => c.colId === activeColumn)}
        index={columns.findIndex((c) => c.colId === activeColumn)}
        updateColumn={updateColumn}
        updateColumnConfig={handleUpdateColumnConfig}
        updateTypeConfig={handleUpdateTypeConfig}
        setOptions={setOptions}
        produceColumn={produceColumn}
        accessGroups={accessGroups}
        setAccessGroups={setAccessGroups}
        validateFieldName={validateFieldName}
        fieldNameValidation={isInvalid}
        setActiveColumn={setActiveColumn}
        activeTableUpdateLoading={activeTableUpdateLoading}
        setEditing={setEditing}
        setDisplayFormatOverride={setDisplayFormatOverride}
        columnErrorsObject={columnErrorsObject}
        setColumnError={setColumnError}
        queryFields={queryFields}
      />
      <ActionSettingsButtons
        dataSourceForPermissionsSet={dataSourceForPermissionsSet}
        setAllLocked={setAllLocked}
        activeColumn={activeColumn}
        colDef={columns.find((c) => c.colId === activeColumn)}
        columnCount={columns.length}
        updateSettings={updateSettings}
        handleSetDeleteColumn={handleSetDeleteColumn}
        isDirty={isDirty}
        columnErrorsObject={columnErrorsObject}
        activeTableUpdateLoading={activeTableUpdateLoading}
        editing={editing}
      />
    </Container>
  );
}
