import React, { useEffect, useMemo } from "react";
import FilterEditor from "../../../Pages/DataManagement/DataExplorer/Filters/FilterEditor";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
import { PanelMessage, PanelTitle } from "../GuiEditorSections/PanelStyles";

export default function FilterPanel(props) {
  const { filterApi, queryId } = props;
  const dispatch = useDispatch();
  const { activeQuery } = useSelector((state) => state.dataSettings);
  // We are likely loading this already, after this confirm where else is coming from
  useEffect(() => {
    if (queryId) {
      dispatch(actions.getQuery(queryId));
    }
  }, [queryId, dispatch]);

  const isParameterized = activeQuery?.type === "parameterized";

  const allFields = useMemo(() => {
    if (!activeQuery) {
      return [];
    }

    if (isParameterized) {
      return activeQuery.parameters.map((parameter) => ({
        ...parameter,
        mapping: { type: parameter.type, displayName: parameter.name },
      }));
    }
    return activeQuery.dataSources[0].fields;
  }, [activeQuery, isParameterized]);

  return (
    <div>
      <PanelTitle>Filter Options</PanelTitle>
      {!filterApi.draftFilters.length ? (
        <PanelMessage>There are no filters set for this chart</PanelMessage>
      ) : null}
      <FilterEditor
        filterApi={filterApi} // Not really, need to check where this is being used
        filters={filterApi.draftFilters}
        handleSetField={filterApi.setField}
        setOperator={filterApi.setOperator}
        setValues={filterApi.setValues}
        dispatch={dispatch}
        queryId={queryId}
        fields={allFields}
        isParameterized={isParameterized}
      />
    </div>
  );
}
