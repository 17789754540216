import React, { useRef } from "react";
import RemoveDot from "./RemoveDot";
import styled from "@emotion/styled";
import { inputBorders } from "../../../styles/styledSnippets";
import Select from "../Select/Select";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { scrollbar } from "../../../styles/styledSnippets";
import IOButton from "../Button/IOButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripHorizontal } from "@fortawesome/pro-solid-svg-icons";
const Input = styled.input`
  font-size: 12px;
  ${(props) => inputBorders(props)}

  width: 240px;
  box-sizing: border-box;

  &:focus {
    border: 2px solid #2684ff;
    outline: none;
  }

  margin-right: 20px;
`;

const Options = styled.div`
  //position: absolute;
  //top: 46px;
  width: 242px;
  z-index: 2;
  .list__control {
    border-radius: 5px; /* Example: Rounded corners */
    border: 1px solid #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
    &:hover {
      border-color: #2684ff;
    }
  }
  div {
    ${(props) => scrollbar(props)}
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  &:hover {
    [data-cy="sorting-drag-handle"] {
      opacity: ${(props) =>
        props.globalEdit || props.addingMode
          ? 0
          : props.globalDragging
          ? 0
          : 1};
    }
  }
  [data-cy="sorting-drag-handle"] {
    opacity: ${(props) => (props.dragging ? 1 : 0)};
  }
`;

export default function InputSetRow(props) {
  const {
    handleRemove,
    editingIndex,
    commitChange,
    startEdit,
    showLabels,
    item,
    tempValue,
    i,
    handleChange,
    handleEnter,
    listMode,
    reset,
    setTempValue,
    addingMode,
    loading,
    cy,
    options: propsOptions = [],
    dragHandleProps,
    isDragDisabled,
    isDragging,
    globalDragging,
    globalEdit,
    setGlobalEdit,
  } = props;

  const options = propsOptions.map((o) => ({ value: o, label: o }));

  const ref = useRef(null);
  const inputRef = useRef(null);
  useOnClickOutside(ref, reset);
  const active = editingIndex === i;

  function handleSelection(e) {
    setTempValue(e);
    commitChange(i, "value", e);
  }

  const valueText = (editingIndex === i ? tempValue.value : item.value) ?? "";

  function handleBlur() {
    commitChange(i, "value", { value: valueText });
    setGlobalEdit(false);
  }

  return (
    <RowContainer
      dragging={isDragging}
      globalDragging={globalDragging}
      globalEdit={globalEdit}
      addingMode={addingMode}
    >
      {!addingMode ? (
        <RemoveDot
          cy="input-set-remove"
          relative
          onClick={() => handleRemove(i)}
        />
      ) : null}
      <div style={{ position: "relative" }}>
        {active && listMode ? (
          <Options ref={ref}>
            <Select
              options={options}
              onChange={handleSelection}
              menuIsOpen
              value={item}
              cy="custom-option-select"
              isLoading={loading}
            />
          </Options>
        ) : (
          <Input
            value={valueText}
            data-cy={cy || "input-set-value"}
            onChange={(e) => handleChange(e, "value")}
            onBlur={handleBlur}
            onKeyDown={(e) => handleEnter(e, i, "value")}
            onFocus={() => startEdit(i)}
            active={editingIndex === i}
            placeholder="Enter value..."
            ref={inputRef}
          />
        )}
      </div>

      {showLabels && (
        <Input
          value={(editingIndex === i ? tempValue.label : item.label) ?? ""}
          data-cy="input-set-label"
          onChange={(e) => handleChange(e, "label")}
          onBlur={() => commitChange(i, "label")}
          onKeyDown={(e) => handleEnter(e, i, "label")}
          onFocus={() => startEdit(i)}
        />
      )}
      {/* Drag Handle Icon */}
      {!isDragDisabled && (!globalEdit || isDragging) && (
        <IOButton
          {...dragHandleProps} // Apply the drag handle props here
          outline
          style={{
            padding: 10,
            alignSelf: "flex-end",
            marginBottom: 2,
            cursor: "grab",
          }}
          title="Drag to reorder options"
          data-cy="sorting-drag-handle"
          onMouseOver={() => inputRef.current && inputRef.current.blur()}
        >
          <FontAwesomeIcon icon={faGripHorizontal} />
        </IOButton>
      )}
    </RowContainer>
  );
}
