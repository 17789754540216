import React, { useMemo } from "react";
import IOButton from "../../../UI/Form/Button/IOButton";

import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrumpet } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "emotion-theming";
import Flex from "../../../UI/Flex/Flex";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: ${(props) => (props.selectedColumn ? 18 : 60)}px;
  svg {
    margin-right: 6px;
    position: relative;
    left: -4px;
    top: -1px;
  }
`;
export default function ActionSettingsButtons(props) {
  const {
    dataSourceForPermissionsSet,
    updateSettings,
    isDirty,
    handleSetDeleteColumn,
    activeColumn,
    setAllLocked,
    columnCount,
    columnErrorsObject,
    colDef,
    activeTableUpdateLoading,
    editing,
  } = props;

  const theme = useTheme();

  const isInvalid =
    !!columnErrorsObject && !!Object.values(columnErrorsObject).length;

  const errorsTitleMessage = useMemo(() => {
    return Object.values(columnErrorsObject ?? {})
      .map((errorStrings) => errorStrings.join("\n"))
      .join("\n");
  }, [columnErrorsObject]);

  const cantSaveMessage =
    errorsTitleMessage ||
    (!isDirty && "There are no changes to save.") ||
    (activeTableUpdateLoading && "Currently saving/loading.") ||
    (editing && "Currently editing a field.");

  return (
    <Container selectedColumn={!!activeColumn}>
      <div
        style={{
          width: 110,
        }}
      >
        <IOButton
          cy="delete-field"
          fit
          color="#ff704f"
          onClick={handleSetDeleteColumn}
          disabled={
            !!(!activeColumn || columnCount === 1 || colDef?.queryOnly) ||
            activeTableUpdateLoading
          }
        >
          <FontAwesomeIcon icon={["fal", "trash"]} />
          Delete
        </IOButton>
      </div>
      <IOButton
        standard
        onClick={updateSettings}
        disabled={
          !!(!isDirty || isInvalid) || activeTableUpdateLoading || editing
        }
        title={cantSaveMessage || ""}
        cy="save-active-settings"
        processing={activeTableUpdateLoading}
      >
        Save
      </IOButton>
      <div style={{ marginLeft: 26 }}>
        <IOButton outline onClick={setAllLocked}>
          Lock all columns
        </IOButton>
      </div>

      {!dataSourceForPermissionsSet ? (
        <Flex
          style={{
            color: theme.text.primary,
          }}
        >
          <FontAwesomeIcon
            icon={faTrumpet}
            title="Permissions are currently not enabled for this Active Table. Make a Save to enable permissions."
            style={{
              boxSizing: "border-box",
              height: "100%",
              aspectRatio: 1,
              backgroundColor: "orange",
              borderRadius: "50%",
              border: "0.2rem solid black",
              padding: "0.3rem",
            }}
          />
        </Flex>
      ) : null}
    </Container>
  );
}
