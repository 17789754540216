import React, { useRef, useEffect } from "react";
import { axisLeft, axisRight } from "d3-axis";
import styled from "@emotion/styled";
import { select } from "d3-selection";
import PropTypes from "prop-types";
import { useTheme } from "emotion-theming";
import formatter from "../../utils/formatters/formatter";
import { range } from "d3-array";
import determineTickFormat from "./applyYAxisFormat";

const G = styled.g(
  ({ theme, color, hideLines, hideTicks, translateLabel, yAxisTypeLabel }) => `
    line {
        stroke: ${theme.divider};
        ${hideTicks ? "display: none;" : null}
    }
    path {
        stroke: ${theme.divider};
        ${hideLines ? "display: none;" : null}
    }
    text {
        fill: ${theme.text.secondary};
        transform: translateX(${translateLabel}px);
        font-size: ${yAxisTypeLabel ? 8 : 10}px;
    }
`
);

export const Label = styled.g`
  font-size: 11px;
  font-weight: 400;
  fill: ${(props) => props.theme.text.secondary};
  text-anchor: middle; /* Horizontal alignment: centers the text */
`;

export default function YAxis(props) {
  const {
    yScale,
    yTicksCount,
    yTicksColor,
    hideYAxisLine,
    hideYAxisLabel,
    hideYAxisTicks,
    yAxisGrid,
    yAxisGridColor,
    width,
    orient,
    translateAxis,
    translateLabel,
    yAxisFormat,
    yAxisTypeLabel,
    domain,
    translateRightLabel,
  } = props;

  const orientAxis = {
    left: axisLeft,
    right: axisRight,
  };

  const yAxis = useRef(null);
  const theme = useTheme();

  const preferredFormat = determineTickFormat(yAxisFormat, yScale.domain());

  const [min, max] = domain || []; // What does this do?
  const step = (max - min) / yTicksCount;

  const doFormat = (val) =>
    formatter(val, preferredFormat.format || yAxisFormat);

  const rangeValues = [...range(min, 0, step), ...range(0, max + step, step)];

  useEffect(() => {
    select(yAxis.current)
      .attr("data-cy", orient === "right" ? "y-axis-right" : "y-axis")
      .attr(
        "transform",
        translateAxis ? `translate(${translateAxis}, 0)` : null
      )
      .call(
        orientAxis[orient](yScale)
          .tickPadding([3])
          .tickSize(yAxisGrid ? -width : 7) // tickSizeInner default
          .tickFormat((v) => doFormat(v)?.toString().replace("G", "B"))
          .ticks(preferredFormat.tickCount || yTicksCount)
          .tickValues(domain ? rangeValues : null)
      )
      .selectAll("path")
      .style("stroke", theme.divider)
      .style("display", () => (hideYAxisLine ? "none" : null));

    select(yAxis.current)
      .selectAll("line")
      .style("stroke", theme.divider)
      .style("display", () => (hideYAxisTicks ? "none" : null));
  });

  return (
    <>
      <G
        hideLines={hideYAxisLine}
        hideTicks={hideYAxisTicks}
        color={yTicksColor}
        gridColor={yAxisGridColor}
        ref={yAxis}
        translateLabel={translateLabel}
        yAxisTypeLabel={yAxisTypeLabel}
        data-cy={orient === "right" ? "y-axis-right" : "y-axis"}
      />
      {yAxisTypeLabel && !hideYAxisLabel ? (
        <Label
          data-cy="y-axis-label"
          // Dynamically calculate the vertical center of the axis
          transform={`translate(${translateRightLabel || -44}, ${
            yScale.range()[0] / 2
          }) rotate(-90)`}
        >
          <text>{yAxisTypeLabel}</text>
        </Label>
      ) : null}
    </>
  );
}

YAxis.defaultProps = {
  yTicksCount: 10,
  yAxisFormat: "$,.2s",
  orient: "left",
  translateLabel: -5,
};

YAxis.propTypes = {
  yScale: PropTypes.func.isRequired,
  yTicksCount: PropTypes.number,
  yTicksColor: PropTypes.string,
  hideYAxisLine: PropTypes.bool,
  hideYAxisTicks: PropTypes.bool,
  yAxisGrid: PropTypes.bool,
  yAxisFormat: PropTypes.string,
};
