import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";

import ActiveTableNavigationShelf from "./ActiveTableNavigationShelf";
import useActiveTableSettings from "./useActiveTableSettings";
import ActiveTableSettings from "./ActiveTableSettings/ActiveTableSettings";
import isEqual from "react-fast-compare";
import ActiveGridDisplay from "./Grid/ActiveGridDisplay";
import useToggleArray from "../../hooks/useToggleArray";
import { unique } from "../../utils/func";
import DataError from "./DataError";
import useViewSelector from "./ActiveTableViews/useViewSelector";
import usePrevious from "../../utils/usePrevious";
import { isObject } from "lodash-es";
import {
  reportUpdatedRowViaWebsocket,
  reportBulkUpdateViaWebsocket,
} from "../../store/actions/activeTable";
import { useLaravelEchoListen } from "../../hooks/useLaravelEcho";
import { useDispatch, useStore } from "react-redux";
import { handleError } from "../../utils/errorHandling";
import { assembleColumnsAndVisibleFields } from "./ActiveTableViews/useActiveTableViewManager";
import {
  getBulkUploadPayloadFromUpdateEvent,
  getActiveTableAndViewUuidFromState,
  isActiveTableColumnTypeFile,
  performBulkUpdateOnLocalData,
} from "../../utils/activeTable";
import useWarnBeforeTabWindowClose from "../../hooks/useWarnBeforeTabWindowClose";
import { getOrderedKeys } from "./Grid/common";

export default function ActiveTable(props) {
  const {
    reloadData,
    checkedMenuFilters,
    queryFields,
    dataSourceUuid,
    activeTableDataLoading,
    activeTableUpdateLoading,
    activeTableWebsocketChannel,
  } = props;

  const [localDataLoading, setLocalDataLoading] = useState(false);
  const dataLoading = activeTableDataLoading || localDataLoading;

  const dispatch = useDispatch();
  const { getState } = useStore();

  const [firstLoad, setFirstLoad] = useState(true);

  const availableViews = (props.views ?? []).filter((view) => {
    const { displaySettings } = view ?? {};

    if (!displaySettings.viewVisibilityUserUuid) {
      return true;
    }

    return props?.user?.uuid === displaySettings.viewVisibilityUserUuid;
  });

  const { selectedView, setSelectedView } = useViewSelector({
    views: availableViews,
  });

  // do not consider undefined
  const prevFilters = usePrevious(checkedMenuFilters) ?? [];
  const prevViewUuid = usePrevious(selectedView) ?? null;

  const viewUpdated = !isEqual(prevViewUuid, selectedView);
  const filtersUpdated = !isEqual(prevFilters, checkedMenuFilters);

  // for case when new created table has no any view
  const noViewsAvalable = availableViews.length === 0 && firstLoad;

  const refreshData = useCallback(() => {
    reloadData(selectedView);
    setFirstLoad(false);
  }, [reloadData, selectedView]);

  // query exec data loading
  useEffect(() => {
    if (!getState().activeTable.currentTable) {
      // This check avoids a console error when navigating away.
      return;
    }
    if (viewUpdated || filtersUpdated || noViewsAvalable) {
      refreshData();
    }
  }, [refreshData, filtersUpdated, noViewsAvalable, viewUpdated, getState]);

  const [settingsMode, setSettingsMode] = useState(false);
  const [collapsed, toggleCollapsed, emptyCollapsed, replaceCollapsed] =
    useToggleArray();
  const adminMode = props.user && props.user.role === "tenant_owner";

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState(props.data || []);
  const [splitBy, setSplitBy] = useState(null);
  const [stringMatch, setStringMatch] = useState("");

  const handleChangeRef = useRef(handleChange);
  handleChangeRef.current = handleChange;

  const {
    nextConfig,
    columns,
    updateColumn,
    setColumns,
    setAllLocked,
    defaultRow,
    addColumn,
    setOptions,
    produceColumnByUuid,
    setAccessGroups,
    isDirty,
    updateConfig,
    prevConfig,
    validateFieldName,
    convertToApiColumns,
    columnErrorsObject,
    setColumnError,
    setDisplayFormatOverride,
    dataSourceForPermissionsSet,
  } = useActiveTableSettings(
    props.config,
    props.user,
    queryFields,
    dataSourceUuid,
    handleChangeRef
  );

  useEffect(() => {
    setRowData(props.data ?? []);
  }, [props.data]);

  useEffect(() => {
    updateConfig(props.config);
  }, [props.config, updateConfig]);

  const [savingReferenceSets, setSavingReferenceSets] = useState(new Set());
  const savingReferences = useMemo(() => {
    return {
      add: (newReference) =>
        setSavingReferenceSets((prev) => new Set(prev).add(newReference)),
      delete: (newReference) =>
        setSavingReferenceSets((prev) => {
          const newSet = new Set(prev);
          newSet.delete(newReference);
          return newSet;
        }),
    };
  }, []);

  const areTherePendingSaves = !!savingReferenceSets.size;

  const { prompt } = useWarnBeforeTabWindowClose(areTherePendingSaves, {
    message: leavePageMessage,
  });

  async function handleChange(event) {
    const saveSymbol = Symbol();

    const { currentTable, currentViewUuid, queryFields } =
      getActiveTableAndViewUuidFromState(getState());
    const isColumnTypeFile = isActiveTableColumnTypeFile(event.colDef.type);

    const views = currentTable.views ?? [];

    const view = views.find((v) => v.uuid === currentViewUuid) ?? views[0];
    const visibleFields = view?.visibleFields ?? [];

    const anyFieldAggregated = visibleFields.some((v) => v.aggregationType);

    if (anyFieldAggregated) {
      const payload = getBulkUploadPayloadFromUpdateEvent(
        event,
        visibleFields,
        currentTable.columns,
        queryFields
      );
      const hasFileUpload = Object.values(payload.values ?? {}).some(
        (v) => v instanceof File
      );
      if (hasFileUpload) {
        setLocalDataLoading(true);
      }
      try {
        savingReferences.add(saveSymbol);
        if (!isColumnTypeFile) {
          // We need to optimistically update the data here, otherwise it would
          // jump back to the previous value immediately.
          setRowData((prevRowData) =>
            performBulkUpdateOnLocalData(prevRowData, payload)
          );
        }
        const response = await props.bulkUpdate(payload, hasFileUpload);
        dispatch(reportBulkUpdateViaWebsocket(response.data.data));
      } catch (e) {
        handleError(e);
      } finally {
        savingReferences.delete(saveSymbol);
        if (hasFileUpload) {
          setLocalDataLoading(false);
        }
      }
      return;
    }

    // row uuid or joinId identifier
    const key = "uniqueRowUuid";

    // editing row
    const row = rowData.find((row) => row[key] === event.data[key]);

    if (row) {
      // column name
      const name = event.colDef.field;
      // updated row
      const updatedRow = { ...row, [name]: event.value };
      // if uuid is not exist then its mean we adding row or updating view column data from joined mode
      try {
        savingReferences.add(saveSymbol);
        let promise;
        if (!event.data.uuid) {
          promise = props.createRow(updatedRow, key);
        } else {
          promise = props.updateRow(updatedRow, name);
        }

        if (!isObject(event.value) && !isColumnTypeFile) {
          setRowData((prevRowData) => {
            return prevRowData.map((item) =>
              item[key] === updatedRow[key] ? updatedRow : item
            );
          });
        }
        await promise;
      } catch (e) {
        handleError(e);
      } finally {
        savingReferences.delete(saveSymbol);
      }
    }
  }

  function handleSplit(value) {
    setSplitBy(value);
  }

  function handleAdd() {
    // setAddedRow(defaultRow); // store this just to keep track of new row (we might want multiples)
    setRowData((d) => [defaultRow, ...d]);
  }

  const activeView = availableViews.find((v) => v.uuid === selectedView);
  const noViews = !availableViews.length;
  /** @type VisibleField[] */
  const visibleFields = activeView?.visibleFields ?? [];

  // split by from view settings
  const viewSplitBy = activeView?.displaySettings?.splitBy;
  const viewSplitByOption = viewSplitBy
    ? { value: viewSplitBy, label: viewSplitBy }
    : null;

  const viewSplitBySortDirection =
    activeView?.displaySettings?.splitBySortDirection ?? "asc";

  function updateSettings() {
    props.updateSettings({ ...nextConfig, activeView });
  }

  const handleUpdateColumnConfig = (
    colId,
    nextValue,
    updateMode,
    index,
    nextColumnConfig
  ) => {
    const nextColumns = updateColumn(index, nextColumnConfig);
    const updatedNextConfig = {
      ...nextConfig,
      columns: nextColumns,
      activeView,
    };

    if (colId === "temp") {
      return props.updateColumnConfig(updatedNextConfig);
    } else {
      updateExistingColumn();
    }
    function updateExistingColumn() {
      const prevColumn = prevConfig.columns.find((c) => colId === c.uuid);
      const fieldNameChanged =
        updateMode === "name" && !isEqual(prevColumn.name, nextValue);
      const typeChanged =
        updateMode === "type" && !isEqual(prevColumn.type, nextValue);

      if (fieldNameChanged || typeChanged) {
        props.updateColumnConfig(updatedNextConfig);
      }
    }
  };

  function performDeleteColumn(colId) {
    const nextColumns = columns.filter((col) => col.colId !== colId);
    setColumns(nextColumns);
    props.updateColumnConfig({
      ...nextConfig,
      columns: convertToApiColumns(nextColumns),
      activeView,
    });
  }

  function handleCollapse(mode) {
    if (mode === "expand") {
      emptyCollapsed();
    } else {
      const split = splitBy?.value ?? viewSplitBy;
      const uniqueData = unique(rowData.map((r) => r[split]));
      const ordered = getOrderedKeys(uniqueData, viewSplitBySortDirection);

      const splitKeys = ordered.map(
        (key, index) => key + "-" + index // to collapse / expand nullable values
      );

      replaceCollapsed(splitKeys);
    }
  }

  const viewFilteredColumns = assembleColumnsAndVisibleFields(
    visibleFields,
    columns,
    queryFields
  ).map(({ column, visibleField }) => {
    const ret = { ...column };
    if (visibleField.aggregationType) {
      ret.editable = false;
      const columnNamePrefix =
        visibleField.aggregationType === "DISTINCT COUNT"
          ? "Distinct"
          : "Total";
      // Hacky implementation. A better solution for the future would be to work
      // with columns not yet in the ColDef format, and only at the very end
      // convert it to ColDef format with all modifiers applied
      // e.g. view column configuration.
      ret.headerName = `${columnNamePrefix} ${ret.headerName}`;
    }
    if (isActiveTableColumnTypeFile(column.type)) {
      // When sorting by file columns, we don't care about the actual filenames,
      // only about whether there is a file or not.
      ret.comparator = (fileValueA, fileValueB) => {
        const valueA = fileValueA !== null;
        const valueB = fileValueB !== null;
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      };
    }

    return ret;
  });

  const inJoinedMode = (function hasJoinedMode() {
    return (
      nextConfig.joinedMode &&
      Object.values(nextConfig.joinedMode).every(Boolean)
    );
  })();

  function getVisibleColumn(name) {
    // if no views we should show all columns in the table so also should show splitBy
    if (!availableViews?.length) {
      return true;
    }

    return viewFilteredColumns.some((col) => col.field === name);
  }

  function getSplitByDefault() {
    return getVisibleColumn(viewSplitBy) ? viewSplitByOption : null;
  }

  const showSplitBy = (function getShowSplitByFlag() {
    if (!rowData.length) {
      return false;
    } else if (!availableViews.length) {
      return true;
    }

    return !!viewFilteredColumns.length;
  })();

  const splitByOption = splitBy ?? getSplitByDefault();
  const abortControllersRef = useRef(new Set());
  useEffect(() => {
    const abortControllers = abortControllersRef.current;
    return () => {
      for (const abortController of abortControllers) {
        abortController.abort();
      }
    };
  }, []);

  const onRowChange = useCallback(
    (row) => {
      const abortController = new AbortController();
      abortControllersRef.current.add(abortController);
      dispatch(
        reportUpdatedRowViaWebsocket(
          row.uuid,
          getState,
          props.queryId,
          availableViews.find((v) => v.uuid === selectedView),
          checkedMenuFilters,
          abortController.signal
        )
      ).catch((e) => {
        handleError(e, { showToast: false });
      });
    },
    [
      availableViews,
      checkedMenuFilters,
      dispatch,
      getState,
      props.queryId,
      selectedView,
    ]
  );
  const onBulkUpdateWebsocket = useCallback(
    (data) => {
      dispatch(reportBulkUpdateViaWebsocket(data));
    },
    [dispatch]
  );
  useLaravelEchoListen(
    activeTableWebsocketChannel,
    "ActiveTableRowUpdated",
    onRowChange
  );
  useLaravelEchoListen(
    activeTableWebsocketChannel,
    "ActiveTableBulkUpdated",
    onBulkUpdateWebsocket
  );

  return (
    <div data-cy="active-table-container">
      {settingsMode ? (
        <ActiveTableSettings
          dataSourceForPermissionsSet={dataSourceForPermissionsSet}
          setSettingsMode={setSettingsMode}
          columns={columns}
          setColumns={setColumns}
          updateColumn={updateColumn}
          updateColumnConfig={handleUpdateColumnConfig}
          updateSettings={updateSettings}
          performDeleteColumn={performDeleteColumn}
          addColumn={addColumn}
          setOptions={setOptions}
          produceColumnByUuid={produceColumnByUuid}
          accessGroups={props.accessGroups}
          setAccessGroups={setAccessGroups}
          setAllLocked={setAllLocked}
          isDirty={isDirty}
          data={rowData}
          validateFieldName={validateFieldName}
          columnErrorsObject={columnErrorsObject}
          setColumnError={setColumnError}
          activeTableUpdateLoading={activeTableUpdateLoading}
          setDisplayFormatOverride={setDisplayFormatOverride}
          queryFields={queryFields}
        />
      ) : (
        <div>
          <ActiveTableNavigationShelf
            handleAdd={handleAdd} // Add a row
            openSettings={() => setSettingsMode(true)}
            adminMode={adminMode}
            handleSplit={handleSplit}
            splitBy={splitByOption}
            columns={columns}
            handleCollapse={handleCollapse}
            setStringMatch={setStringMatch}
            views={availableViews}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            queryId={props.queryId}
            handleSaveView={props.saveView}
            deleteView={props.deleteView}
            user={props.user}
            stringMatch={stringMatch}
            joinedMode={nextConfig.joinedMode}
            inJoinedMode={inJoinedMode}
            tbl={nextConfig.tbl}
            showSplitBy={showSplitBy}
            getVisibleColumn={getVisibleColumn}
            refreshData={refreshData}
            saving={areTherePendingSaves}
          />
        </div>
      )}
      <DataError
        dataError={props.dataError}
        dataErrorDetail={props.dataErrorDetail}
        closeMessage={props.closeMessage}
      />
      <ActiveGridDisplay
        rowData={rowData}
        columns={noViews ? columns : viewFilteredColumns}
        handleChange={handleChange} // Update row
        splitBy={splitByOption}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        stringMatch={stringMatch}
        activeTableDataLoading={dataLoading}
        viewSplitBySortDirection={viewSplitBySortDirection}
      />

      {prompt}
    </div>
  );
}

const leavePageMessage =
  "There are changes still being saved.\nAre you sure you want to leave?";
